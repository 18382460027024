@import "src/styles/constants";

.create-survey {
  display: flex;
  flex-direction: column;
  background: $colorBlue10;
  border: 1px solid $colorBlue20;
  border-radius: 7px;
}

.survey-results {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;

    .ant-select-selection-placeholder {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $colorGray90;
    }
  }

  &__select {
    max-width: 364px;
  }

  &__chart-container:not(:last-child){
    margin-bottom: 20px;
  }

  &__bar-container {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
    position: relative;
    margin-block: 8px;
  }

  &__bar {
    width: 101%;
  }
  &__bottom-line {
    position: absolute;
    height: 1px;
    background-color: $colorGray40;
    // top: 92%;
    left: 0;
    width: 100%;
  }
}
.contract-category-label {
  font-weight: 600;
  color: $colorGray70;
}
.contract-category-title {
  margin-top: auto;
}

.user-select-container {
  width: 50%;
}

.chart-bottom-label {
  margin-top: -20px;
}