@import "styles/constants";


.business-details-container{
  &__empty-state{
    &__wrapper{
      max-width: 461px;
      text-align: center;
    }
    margin-top: 20%;
  }
  &__wrapper{
    max-width: 364px
  }
}