@import "styles/constants";
.compliance__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.compliance__datepicker-container {
    display: flex;
    flex-direction: column;
    .datepicker__label {
        position: relative;
        margin-bottom: 2px;
    }
}

.ant-table-cell:has(.table__chart) {
    width: 556px;
}

.table__chart {
    display: flex;
    flex-direction: row;
    width: 556px;
    .table__chart__colored{
        position: relative;
        .compliance_required_line{
            top: 0;
            height: 16px;
            width: 1px;
            background-color: $colorGray90;
            position: absolute;
        }

    }
}
.compliance__legend {
    align-self: center;
    gap: 16px;
    display: flex;
    flex-direction: row;
    height: 20px;
    margin-top: 16px;
}
.legend__rectangle {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.legend__description {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.legend__rectangle_color_compliant {
    background-color: $colorBlue50;
}

.legend__rectangle_color_non-compliant {
    background-color: $colorRed10;
}

.total__title {
    color: $colorGray70;
}
.total__subtitle {
    color: $colorBlue90;
    margin-bottom: 10px;
}

.description__summary {
    .typography-headline-5 {
        margin-bottom: 12px;
    }
}
.description__summary-division {
    .typography-label  {
        margin-bottom: 6px;
    }
}

.summary-division__part {
    width: 194px;
    display: flex;
    justify-content: space-between;
    color: $colorGray70;
}
.spent__widget {
    position: relative;
    flex-direction: row;
    margin-bottom: 28px;
}

.compliant__chart-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: end;
    align-items: center;
    //height: 100%;
}

.chart-part__description{
    color: $colorGray70;
    margin-top: 33px;
    transform:rotate(-50deg);
    cursor: pointer;
    &.active{
        color: $colorGray90;
    }
}
.chart__container {
    position: relative;
    top:35px;
    left: 26px;
    padding: 0 16px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    gap: 23px;
    align-items: baseline;
    z-index: 10;
    height: 140px;
}

.compliant__chart-part {
    width: 62px;
}
.chart__description{
    width: 100%;
    max-width: 400px;
}
.chart__scale {
    width: 100%;
    position: absolute;
    gap: 11px;
    top: 35px;
    z-index: 2;
    display: flex;
    flex-direction: column;
}

.scale__part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .typography{
        display: flex;
        justify-content: flex-end;
        width: 30px;
    }
}

.scale__line {
    margin-left: 8px;
    display: block;
    height: 1px;
    width: 100%;
    max-width: 1040px;
    background-color: $colorGray30;
}

.compliance__checkbox {
    margin-left: 19px !important;
}

.table-wrapper{
    &__compilance{
        margin-top: 2px;
    }
}

.chart-tooltip-compliance{
    .ant-tooltip-inner{
        width: max-content;
    }
}