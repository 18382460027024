@import "styles/constants";

.add-bank-account-form{
    max-width: 752px;
    .four-in-row{
        .ant-form-item{
            max-width: 170px;
        }
    }
    .description-row{
        .ant-form-item{
            max-width: 558px;
        }
        #description{
            resize: none;
        }
    }
    .three-in-row{
        .ant-form-item{
            max-width: 170px;
        }
    }
    .long-select{
        max-width: 364px !important;
    }
    .address-type{
        max-width: 170px !important;
    }
}