@import "src/styles/constants";

.button-default {
  &.ant-btn {

    &.ant-btn-primary {
      border: 1px solid transparent;
    }

    &.small {
      padding: 5px 11px;
    }

    &.medium {
      padding: 5px 11px;
      height: 32px;
    }

    &.ant-btn-link {
      padding: 0;
      font-size: 16px;
      line-height: 24px;
    }

    &.upperCase {
      text-transform: uppercase;
    }

    &.inline {
      height: unset;
    }

    svg {
      margin-right: 8px;
    }
  }
}
