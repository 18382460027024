.admin-fee-field {
  max-width: 167px;
}
.contract-details-form--creation {
  .custom-input,
  .custom-datepicker,
  .custom-select {

  }
}
.contract-details-form__grid-row {
  display: grid;
  grid-template-columns: minmax(auto, 364px) minmax(auto, 360px);
  grid-column-gap: 24px;
  .ant-form-item-control-input{
    min-width: 170px;
  }
}
.contract-details-form__grid-column {
  display: grid;
  grid-template-rows: repeat(3, 60px);
  grid-row-gap: 24px;
}

