@import "src/styles/constants";

.reason-cell {
  border-bottom: none;
  word-break: break-all;

  &__title {
    &.typography-label {
      color: $colorGray70;
    }
  }
}