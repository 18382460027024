@import "src/styles/constants";

.invoices-table {
  .ant-table-tbody {
    td.ant-table-cell {
      vertical-align: middle;
    }
  }

  &-wrapper {
    .typography-caption {
      color: $colorGray60;
    }
  }
}
