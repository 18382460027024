@import "src/styles/constants";

.custom-button {
  &.ant-btn {
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 4px;
    border-color: $colorBlue70;
    text-shadow: none !important;
    box-shadow: none !important;
    border: none;

    &:disabled {
      svg path {
        fill: $colorGray50;
      }
    }

    &.ant-btn-primary {
      color: $colorWhite;
      background-color: $colorBlue70;
      border-color: $colorBlue70;

      &:hover {
        background-color: $colorBlue80;
        border-color: $colorBlue80;
      }

      &:focus {
        border: 1px solid $colorBlue100;
        background-color: $colorBlue80;
      }

      &:active {
        background-color: $colorBlue90;
        border-color: $colorBlue90;
      }

      &:disabled,
      &:disabled:hover {
        color: $colorWhite;
        background-color: $colorGray50;
        border-color: $colorGray50;
      }

      &.ant-btn-dangerous {
        color: $colorWhite;
        background-color: $colorRed40;
        border-color: $colorRed40;

        &:hover {
          background-color: $colorRed30;
          border-color: $colorRed30;
        }

        &:focus {
          background-color: $colorRed40;
          border-color: $colorBlue90;
        }

        &:active {
          background-color: $colorRed50;
          border-color: $colorRed50;
        }

        &:disabled,
        &:disabled:hover {
          background-color: $colorGray50;
          border-color: $colorGray50;
        }
      }

      svg path {
        fill: $colorWhite;
      }
    }

    &.ant-btn-default {
      color: $colorBlue70;
      border: 1px solid $colorBlue70;
      background-color: $colorWhite;

      svg path {
        fill: $colorBlue70;
      }

      &:hover {
        color: $colorBlue80;
        border-color: $colorBlue80;
        background-color: $colorBlue10;

        svg path {
          fill: $colorBlue80;
        }
      }

      &:focus {
        color: $colorBlue90;

        svg path {
          fill: $colorBlue80;
        }
      }

      &:active {
        color: $colorBlue90;
        background-color: $colorBlue20;
        border-color: $colorBlue90;

        svg path {
          fill: $colorBlue90;
        }
      }

      &:disabled,
      &:disabled:hover {
        color: $colorGray50;
        background-color: $colorWhite;
        border-color: $colorGray50;
      }

      &.ant-btn-dangerous {
        color: $colorRed40;
        background-color: $colorWhite;
        border-color: $colorRed40;

        &:hover {
          background-color: $colorRed4;
          border-color: $colorRed40;
        }

        &:focus {
          background-color: $colorRed4;
          border-color: $colorBlue90;
        }

        &:active {
          color: $colorRed50;
          background-color: $colorRed8;
          border-color: $colorRed50;
        }

        &:disabled,
        &:disabled:hover {
          color: $colorGray50;
          background-color: $colorWhite;
          border-color: $colorGray50;
        }

        svg path {
          fill: $colorRed40;
        }
      }
    }

    &.ant-btn-ghost {
      color: $colorBlue70;
      border-color: transparent;

      svg path {
        fill: $colorBlue70;
      }

      &:hover {
        color: $colorBlue80;
        background-color: $colorBlue5Alpha;
        border-color: transparent;

        svg path {
          fill: $colorBlue80;
        }
      }

      &:focus {
        color: $colorBlue90;
        background-color: $colorBlue20;
        border-color: $colorBlue20;

        svg path {
          fill: $colorBlue80;
        }
      }

      &:active {
        color: $colorBlue90;
        background-color: $colorBlue20;
        border-color: transparent;

        svg path {
          fill: $colorBlue90;
        }
      }

      &:disabled,
      &:disabled:hover {
        color: $colorGray50;
        background-color: $colorWhite;
        border-color: transparent;

        svg path {
          fill: $colorGray50;
        }
      }
    }
    &.transparent-bg{
      background-color: transparent;
    }

    &.ant-btn-link {
      display: inline-flex;
      color: $colorBlue70;
      border: none;

      &.like-body {
        font-size: 16px;
        line-height: 24px;
        font-weight: 400;
      }

      &:hover {
        color: $colorBlue80;

        svg path {
          fill: $colorBlue80;
        }
      }

      &:focus,
      &:active {
        color: $colorBlue90;

        svg path {
          fill: $colorBlue90;
        }
      }

      &:disabled,
      &:disabled:hover {
        color: $colorGray60;
      }
    }
  }
}
