@import "src/styles/constants";

.invoices-details {
  padding: 24px;
  background-color: $colorGray10;

  .field-with-label {
    flex-grow: 1;
  }
}
